<template>
  <div>
    <div class="absolute">
      <h-tip-dialog v-model="showTip" v-bind:title="label || altlabel" v-bind:tip="tip"></h-tip-dialog>
    </div>
    <v-text-field
      class="h-text-input"
      color="purple darken-2"
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      append-icon="mdi-information-outline"
      :disabled="disabled"
      :outlined="outlined"
      :hide-details="hideDetails"
      :label="label"
      :placeholder="placeholder"
      :counter="counter"
      :maxlength="maxlength"
      :single-line="singleLine"
      :dense="dense"
      @click:append="enableTip()"
    >
    </v-text-field>
  </div>
</template>

<script>
import VTextField from "vuetify/lib/components/VTextField";
import HTipDialog from "./HTipDialog";

export default {
  components: { HTipDialog },
  name: "HTextInput",
  extends: VTextField,
  props: {
    tip: String,
    label: String,
    altlabel: String,
    value: [String, Number],
    outlined: {
      type: Boolean,
      default: true,
    },
    counter: Number,
    maxlength: Number,
    placeholder: String,
    "single-line": Boolean,
    "hide-details": Boolean,
    disabled: Boolean,
    dense: Boolean,
  },
  methods: {
    enableTip() {
      this.showTip = !this.showTip;
    },
  },
  data: () => ({
    showTip: false,
  }),
};
</script>

<style>
.h-text-input .v-text-field__slot > label {
  font-size: 0.9rem;
}
</style>
