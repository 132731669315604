<template>
  <v-row justify="center">
    <v-overlay v-if="overlay" :value="value" :z-index="20">
      <v-dialog
        v-model="value"
        max-width="500"
        v-bind:hide-overlay="true"
        v-bind:persistent="true"
        transition="scale-transition" 
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
            <span v-if="title" class="message">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn color="white" small icon @click.stop="closeDialog()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-html="tip || label" class="text-body-1"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click.stop="closeDialog()"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-overlay>
    <v-dialog
      v-else
      v-model="value"
      max-width="500"
      v-bind:hide-overlay="true"
      v-bind:persistent="true"
      transition="scale-transition"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <span v-if="title" class="message">{{ label || title || altlabel }}</span>
          <v-spacer></v-spacer>
          <v-btn color="white" small icon @click.stop="closeDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-html="tip || label" class="text-body-1"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="closeDialog()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-application .grey.lighten-2 {
  background-color: #acbedb !important;
  border-color: #acbedb !important;
  color: white !important;
}
.v-sheet.v-card {
  border-radius: 15p !important;
}
.v-dialog .v-card .v-card__text {
  padding-top: 25px;
}

.message {
  word-break: break-word;
  padding: 16px;
}
</style>
<script>
export default {
  props: ["value", "tip", "label", "overlay", "title", "altlabel"],
  model: {
    event: "change"
  },
  methods: {
    closeDialog() {
      this.$emit("change", false);
    }
  }
};
</script>
